<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <section class="grid-view">
      <b-card
          v-for="tree in trees"
          :key="tree.id"
          class="ecommerce-card"
          no-body
      >
        <div class="item-img text-center">
          <b-img
              :alt="`${tree.name}-${tree.id}`"
              fluid
              class="card-img-top"
              :src="require(`@/assets/images/trees/${tree.image}`)"
          />
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div class="item-rating item-name">
              <span
                  class="text-body"

              >
                <strong> {{ tree.name }}</strong>
              </span>
            </div>
            <div>
              <h6 class="item-price">
                ${{ parseFloat(tree.price * tree.qty).toFixed(2) }}
                <span class="text-muted">
              ({{ parseFloat((1 / bnbPrice) * (tree.price * tree.qty)).toFixed(4) }} BNB)
            </span>
              </h6>
            </div>
          </div>

          <div class="item-quantity text-center">
            <b-form-spinbutton
                v-model="tree.qty"
                size="sm"
                class="ml-75"
                inline
            />
          </div>
          <b-card-text>
            <small>{{ tree.description }}</small>
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-button
              :disabled="!validNetwork"
              variant="primary"
              tag="a"
              class="btn-cart"
              @click="dispatchBuy(tree)"
          >
            <feather-icon
                icon="ShoppingCartIcon"
                class="mr-50"
            />
            <span>Comprar</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <template #overlay>
      <div class="text-center">
        <b-icon icon="stopwatch" font-scale="3" animation="cylon" />
        <p id="cancel-label">
          Por favor espere...
        </p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
const NATIVE=0;
const YOPO = 1;
const CAOBA = 2;
const ACACIA = 3;
const CEDRO = 4;
const LAUREL = 5;
const PINO = 6;
const CANO_FISTO = 7;
const LIMON = 8;
const NARANJA = 9;
const POMAROSA = 10;
const MARANON = 11;
const GUAYACAN = 12;
const ANON = 13;
const MANGO = 14;
const CACAO = 15;
const GUAMA = 16;
const CAUCHO = 17;

import {
  BOverlay,BIcon, BCard, BImg, BCardBody, BFormSpinbutton, BCardText, BButton
} from 'bootstrap-vue'
import {mapState, mapGetters} from "vuex";

export default {
  name: "TreeList",
  components: {
    BOverlay, BCard, BImg,BIcon, BCardBody, BFormSpinbutton, BCardText, BButton
  },
  data() {
    return {
      showOverlay: false,
      trees: [
        {
          name: 'Yopo',
          description: "El Mimosa trianae Benth o mejor conocido como Yopo peludo, yopo pelú, falso yopo o guayacán yopo es un árbol hasta de 23 m de altura y 50 cm de diámetro, con una copa expandida y rala que proyecta sombra tenue sobre las gramíneas.",
          brand: "Árbol perenne natural",
          price: 6.1,
          qty: 1,
          image: "yopo.png",
          treeId: YOPO,
          id: 1
        },
        {
          name: 'Caoba',
          description: "La caoba (Swietenia mahagoni) es un árbol de la familia de la Meliaceae que alcanza entre 12 y 18 metros. Es nativa, crece en suelo dominicano de forma natural y su madera es una de las más preciadas en el mundo de la ebanistería por sus granos finos, su lustrado y fácil torneado.",
          brand: "Frutal",
          price: 6.1,
          qty: 1,
          image: "caoba.png",
          treeId: CAOBA,
          id: 2
        },
        {
          name: 'Acacia',
          description: "Acacia mangium es una especie de leguminosa del género Acacia, perteneciente a la familia Fabaceae. Acacia mangium alcanza un tamaño de hasta 30 metros de altura, a menudo con un tronco recto.",
          brand: "Árbol perenne natural",
          price: 6.1,
          qty: 1,
          image: "acacia.png",
          treeId: ACACIA,
          id: 3
        },
        {
          name: 'Cedro',
          description: "El cedro rosado o lazcar (Acrocarpus fraxinifolius) es una planta de la familia Fabaceae. Es nativo del sur de la India; es una variedad de rápido crecimiento y muy útil para proyectos agroforestales. Es un árbol maderable por su consistencia y crecimiento.",
          brand: "Árbol perenne natural",
          price: 6.1,
          qty: 1,
          image: "cedro.png",
          treeId: CEDRO,
          id: 4
        },
        {
          name: 'Laurel',
          description: "El laurel es una planta medicinal muy conocida en la gastronomía por su sabor y aroma característico, siendo muy utilizada para darle sabor a las carnes, pescados y sopas. ... Su nombre científico es Laurus nobilis y puede comprarse prácticamente en cualquier supermercado y en ciertas tiendas de productos naturales.",
          brand: "Árbol perenne natural",
          price: 6.1,
          qty: 1,
          image: "laurel.png",
          treeId: LAUREL,
          id: 5
        },
        {
          name: 'Pino',
          description: "Una conífera de tamaño mediano a grande, delgada, con una corona bastante escasa de hojas largas, finas y puntiagudas. Es nativa de las sabanas de la costa caribeña desde Quintana Roo al sur de México hasta Nicaragua por debajo de los 700 m de altitud. Pinus caribaea es un importante árbol de madera y uno de los pocos pinos adaptados a climas tropicales secos y húmedos.",
          brand: "Árbol perenne natural",
          price: 6.1,
          qty: 1,
          image: "pino.png",
          treeId: PINO,
          id: 6
        },
        {
          name: 'Caña Fístula',
          description: "De caña y fístula. Árbol de la familia de las papilionáceas, de unos diez metros de altura , con tronco ceniciento y hojas compuestas , flores amarillas en racimos colgantes , y por fruto vainas cilíndricas de color pardo , que contienen una pulpa negruzca y dulce que se usa en medicina.",
          brand: "Frutal",
          price: 6.1,
          qty: 1,
          image: "canafistula.png",
          treeId: CANO_FISTO,
          id: 7
        },
        {
          name: 'Limón',
          description: "Citrus × limon, el limonero, es un pequeño árbol frutal perenne. Su fruto es el limón o citrón, una fruta comestible de sabor ácido y extremadamente fragante que se usa principalmente en la alimentación.",
          brand: "Árbol perenne natural",
          price: 6.1,
          qty: 1,
          image: "limon.png",
          treeId: LIMON,
          id: 8
        },
        {
          name: 'Naranja',
          description: "El naranjo es un árbol de tamaño mediano, de tres a cinco metros de altura, con copa redondeada y ramas regulares. Un solo tronco, derecho y cilíndrico, verdoso primero y gris después. Las ramas aparecen a un metro, poco más o menos, del suelo.",
          brand: "Frutal",
          price: 6.1,
          qty: 1,
          image: "naranja.png",
          treeId: NARANJA,
          id: 9
        },
        {
          name: 'Pomarrosa',
          description: "Syzygium jambos, de nombre común pomarrosa o perita, es un árbol originario del sudeste asiático perteneciente a la familia Myrtaceae. Se ha cultivado y también naturalizado en América tropical continental, y en las Antillas, donde fue introducida por los ingleses. En Cuba es una especie invasora.",
          brand: "Frutal",
          price: 6.1,
          qty: 1,
          image: "pomarrosa.png",
          treeId: POMAROSA,
          id: 10
        },
        {
          name: 'Marañón',
          description: "Anacardium occidentale, también conocido como cayú, nuez de la India, anacardo, merey, cajú, castaña de cajú, marañón, cajuil, caguil o pepa, es un árbol originario de Centroamérica, del nordeste de Brasil, costa de Colombia y sur de Venezuela. Muchos de sus componentes son utilizados en la elaboración de productos diversos, como por ejemplo dulces, cosméticos y medicamentos",
          brand: "Frutal",
          price: 6.1,
          qty: 1,
          image: "maranon.png",
          treeId: MARANON,
          id: 11
        },
        {
          name: 'Guayacán',
          description: "El guayacán como planta medicinal es utilizado para el resfrío, afecciones renales y reumáticas; fiebre. Dolores por golpes y contusiones. Misma infusión en forma de baños o cataplasmas.",
          brand: "Frutal",
          price: 6.1,
          qty: 1,
          image: "guayacan.png",
          treeId: GUAYACAN,
          id: 12
        },
        {
          name: 'Anón',
          description: "El anón es un árbol de tamaño pequeño, de copa que puede ser abierta o erecta y que posee ramas delgadas y largas; rara vez exceden una altura o ancho de 15 a 20 pies.",
          brand: "Frutal",
          price: 6.1,
          qty: 1,
          image: "anon.png",
          treeId: ANON,
          id: 13
        },
        {
          name: 'Mango',
          description: "El mango de la especie Mangifera indica es una fruta jugosa (más específicamente una drupa) y tiene numerosas variedades con formas, colores, texturas y sabores muy variables. Es redondo a oblongo y puede tener pesos de menos de 50 g a más de 2 kg. Consta de una cáscara, una pulpa comestible (el mesocarpio) y un endocarpio duro que contiene una semilla. Durante el desarrollo del fruto, la cáscara es de un color verde oscuro, que cambia a tonos verdes claros, amarillos, de color naranja, rojos o de color borra de vino al madurar el fruto. Según la variedad, la pulpa del mango maduro es dulce, de textura suave o fibrosa y de color naranja o amarillo. La variedad llamada mango de hilacha es la que mayor cantidad de fibra contiene.",
          brand: "Frutal",
          price: 6.1,
          qty: 1,
          image: "mango.png",
          treeId: MANGO,
          id: 14
        },
        {
          name: 'Cacao',
          description: "Theobroma cacao L. es el nombre científico que recibe el árbol del cacao o cacaotero, nativo de regiones tropicales y subtropicales de América: América tropical, planta de hoja perenne de la familia Malvaceae. Theobroma significa, en griego, «alimento de los dioses. La palabra cacao se cree viene de los lenguajes de la familia mixe-zoque que habrían hablado los olmecas. En maya yucateco, kaj significa amargo y kab significa jugo. Alternativamente, algunos lingüistas proponen la teoría de que en el correr del tiempo pasó por varias transformaciones fonéticas que dieron paso a la palabra cacaoatl, la cual evolucionó después a cacao.",
          brand: "Frutal",
          price: 6.1,
          qty: 1,
          image: "cacao.png",
          treeId: CACAO,
          id: 15
        },
        {
          name: 'Guama',
          description: "La guama, guaba, pepeto, paterna o inga es una planta nativa de El Salvador, Honduras, Guatemala, Venezuela, Ecuador, Colombia y Perú entre otros. El árbol alcanza los 20 metros de altura y fructifica generalmente en época lluviosa razón por la cual solo se encuentra en ciertas temporadas del año, en condiciones ideales el árbol puede fructificar dos veces por año. Su fruta viene en una vaina generalmente gruesa, por dentro se encuentran sus semillas recubiertas de una carnosidad suave como una nube, comestible y de sabor dulce.",
          brand: "Frutal",
          price: 6.1,
          qty: 1,
          image: "guama.png",
          treeId: GUAMA,
          id: 16
        },
        {
          name: 'Caucho Llanero',
          description: "Hevea brasiliensis, llamado comúnmente árbol del caucho, jacio del Orinoco,​ shiringa o seringueira (del portugués), es un árbol de la familia de las euforbiáceas de 20 a 30 m de altura (excepcionalmente 45 m). El tronco es recto y cilíndrico de 30 a 60 cm de diámetro, de madera blanca y liviana.",
          brand: "Frutal",
          price: 6.1,
          qty: 1,
          image: "caucho.png",
          treeId: CAUCHO,
          id: 17
        }
      ]
    }
  },
  computed: {
    ...mapState({
      cvrContract: state => state.w3.cvrContract,
      web3: state => state.w3.web3,
      metamaskMsg: state => state.w3.MetamaskMsg,
      bnbPrice: state => state.w3.bnbPrice
    }),
    ...mapGetters({
      validNetwork: 'w3/validNetwork',
      address: 'w3/address',
    })

  },
  methods: {
    async dispatchBuy(tree) {
      if (!this.validNetwork) {
        await this.$store.dispatch('notification/notifiedError', this.metamaskMsg.METAMASK_TEST_NET)
        return;
      }
      this.$store.dispatch('w3/getBNBPrice')
      this.showOverlay = true
      const qty = tree.qty
      const treeId = tree.treeId

      const totalPrice = qty * tree.price
      const bnbPrice = parseFloat((1 / this.bnbPrice) * totalPrice).toFixed(4)
      const value =this.web3.utils.toWei(bnbPrice.toString())
      const self = this
      this.cvrContract.methods.purchase(qty, treeId).send({from: this.address, value})
          .on('transactionHash', function (hash) {
            if (hash) {
              self.showOverlay = false
              self.$store.dispatch('notification/notifiedTxOK', {
                hash: hash,
                message: "Compra del Coin Verde pendiente de confirmación"
              })
            }
          }).on('error', function (e) {
           self.showOverlay = false
            if(e.code === 4001) return;
            self.$store.dispatch('notification/notifiedError', "No se puede realizar la compra en este momento. Intente más tarde.")
            console.log(e)
      })

    }
  }

}
</script>

<style scoped>

</style>
<template>
  <div style="height: inherit">
    <tree-list/>
  </div>
</template>

<script>
import TreeList from "./TreeList";

export default {
  components: {
    TreeList
  },
  data() {
    return {

    }
  },
  methods: {

  },
  setup() {

  },
  beforeCreate() {
    this.$store.dispatch('w3/getBNBPrice')
  }
}
</script>

<style lang="scss">
@import "../@core/scss/base/pages/app-ecommerce.scss";
</style>

